<template>
  <div class="mb-4">
    <ul class="nav nav-pills nav-justified">
      <li class="nav-item waves-effect waves-light">
        <router-link
          class="nav-link"
          :class="{ 
            active: seccion == 'productos'
          }"
          :to="{ name: 'listadoProductos' }"
        >
          Productos
        </router-link>
      </li>
      <li class="nav-item waves-effect waves-light">
        <router-link
          :to="{ name: 'listadoCategoriasProductos' }"
          class="nav-link"
          :class="{ 
            active: seccion == 'categorias'
          }"
        >
          Categorías
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CmpSecciones",
  props: ['seccion']
}
</script>
