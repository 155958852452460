<template>
  <Layout tituloPagina="Productos">
    <div class="row">
      <div class="col-lg-5">
        <cmp-secciones seccion="productos"></cmp-secciones>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2 col-5">
        <div class="form-check form-switch form-switch-lg mb-3">
          <input
            :checked="activos"
            @change="
              activos = !activos,
              refrescarProductos()
            "
            class="form-check-input"
            type="checkbox"
            id="banderaActivos"
          />
          <label
            class="form-check-label"
            for="banderaActivos"
          >
            Activos
          </label>
        </div>
      </div>
      <div class="col-md-8 mb-3 col-7">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarProductos()
          "
          :datoini="dato"
          placeholder="Dato del producto"
        ></filtrador>
      </div>

      <div class="col-md-2 text-right">
        <router-link :to="{ name: 'nuevoProducto' }" class="btn btn-success">
          <i class="mdi mdi-plus-thick"></i>
          Nuevo
        </router-link>
      </div>
    </div>
    <div class="row mb-4 mt-2">
      <div class="col-md-4 offset-md-8 text-right">
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{nProductosEnPag}} productos en página
        </span>
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{nProductosTotales}} productos en total
        </span>
      </div>
    </div>

    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive">
          <table class="table table-hover table-nowrap" style="min-width: 1240px" id="tabla-productos">
            <thead>
              <tr>
                <th style="width: 50px">Foto</th>
                <th style="width: 70px">ID</th>
                <th>Nombre</th>
                <th style="width: 100px">Modelo</th>
                <th>Categoría</th>
                <th style="width: 100px">Tipo</th>
                <th style="width: 70px">Estado</th>
                <th style="width: 100px">Sin IVA</th>
                <th style="width: 100px">Publico</th>
                <th style="width: 180px">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="producto in productos" :key="producto.id">
                <td>
                  <img
                    :src="`${API}/productos/${producto.id}/imagen?tipo_foto=64x64&_tk=${tk}`"
                    v-if="producto.tiene_imagen_asignada"
                    class="iconproducto"
                  />
                </td>
                <td class="fw-semibold">{{ producto.id }}</td>
                <td>
                  <router-link :to="{ name: 'edicionProducto', params: { id: producto.id } }" v-if="producto.id_categoria != 2">
                    {{ producto.nombre }}
                  </router-link>
                  <router-link :to="{ name: 'edicionPlanServicio', params: { id: producto.id } }" v-if="producto.id_categoria == 2">
                    {{ producto.nombre }}
                  </router-link>
                </td>
                <td>{{ producto.modelo }}</td>
                <td>
                  <template
                    v-if="
                      producto.categoria != undefined &&
                        producto.categoria != null
                    "
                  >
                    {{ producto.categoria.nombre }}
                  </template>
                </td>
                <td>
                  <span
                    class="badge bg-gradient font-size-12"
                    :title="producto.servicio ? 'Servicio' : 'Producto'"
                    :class="{
                      'bg-primary': !producto.servicio,
                      'bg-warning': producto.servicio
                    }"
                  >
                    {{ producto.servicio ? 'srv' : 'prd' }}
                  </span>
                </td>
                <td>
                  <span
                    class="badge bg-success bg-gradient font-size-12"
                    v-show="producto.activo"
                  >
                    Activo
                  </span>
                  <span
                    class="badge bg-danger bg-gradient font-size-12"
                    v-show="!producto.activo"
                  >
                    Inactivo
                  </span>
                </td>
                <td class="text-right">
                  {{ formatoMoneda(producto.precio) }}
                </td>
                <td class="text-right">
                  <strong>
                    {{ formatoMoneda(precioPublico(producto)) }}
                  </strong>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'edicionProducto',
                      params: { id: producto.id }
                    }"
                    v-if="producto.id_categoria != 2"
                    class="btn btn-warning btn-sm"
                  >
                    <i class="mdi mdi-content-save-edit-outline"></i>
                    Editar
                  </router-link>

                  <router-link
                    class="btn btn-warning btn-sm"
                    :to="{
                      name: 'edicionPlanServicio',
                      params: { id: producto.id }
                    }"
                    v-if="producto.id_categoria == 2"
                  >
                    <i class="mdi mdi-square-edit-outline font-size-13"></i>
                    Editar
                  </router-link>

                  <button
                    class="btn btn-danger btn-sm"
                    @click="preguntaEliminarProducto(producto)"
                  >
                    <i class="mdi mdi-trash-can-outline"></i>
                    Eliminar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center">
      <paginador
        :pag="pagina"
        :totpag="ultimaPagina"
        :epp="por_pagina"
        v-on:cargar-pagina="cargarPagina"
        v-on:epp-actualizado="
          por_pagina = $event,
          refrescarProductos()
        "
      ></paginador>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'

import API from '@/API.js'
import Paginador from '@/components/Paginador.vue'
import Filtrador from '@/components/Filtrador.vue'
import CmpSecciones from './CmpSecciones.vue'
import ProductoSrv from '@/services/ProductoSrv.js'
import SistemaSrv from '@/services/SistemaSrv.js'
import accounting from 'accounting/accounting.js'
import Swal from 'sweetalert2'

export default {
  name: 'ListadoProductos',
  components: { Layout, CmpSecciones, Paginador, Filtrador},
  data() {
    return {
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      baseUrl: window.location.origin,
      dato: '',
      n: 0,
      pagina: 1,
      pIva: 0,
      por_pagina: parseInt(localStorage.getItem('argusblack.listadoProductos.por_pagina') || 10),
      productos: [],
      ultimaPagina: 0,
      activos: true,
      nProductosEnPag:0,
      nProductosTotales:0
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created: function() {
    var self = this

    // Carga de Querys
    if (this.$route.query.dato) this.dato = this.$route.query.dato
    if (this.$route.query.pagina) this.pagina = parseInt(this.$route.query.pagina)
    if (this.$route.query.por_pagina) this.por_pagina = parseInt(this.$route.query.por_pagina)
    if (this.$route.query.activos) this.activos = this.$route.query.activos == 'true' ? true : false

    self.inicio()
  },

  methods: {
    cargarPagina: function(n) {
      var self = this
      self.pagina = n
      self.refrescarProductos()
    },

    cargarPorcentajeIva: function() {
      var self = this

      SistemaSrv.valorAjuste('iva').then(response => {
        self.pIva = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar le porcentaje de IVA'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    eliminar: function(producto) {
      var self = this

      ProductoSrv.eliminar(producto.id).then(response => {
        self.refrescarProductos()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo eliminar el servicio'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    formatoMoneda: function(numero) {
      return accounting.formatMoney(numero, { symbol: this.monedaSistema.simbolo, miles: ",", decimal: "." })+' '+this.monedaSistema.codigo
    },

    inicio: function() {
      var self = this

      // Cargas iniciales
      self.refrescarProductos()
      self.cargarPorcentajeIva()
    },

    /**
     * Método que devuelve el precio del producto
     */
    precioProducto: function(producto) {
      return producto.precio
    },

    precioPublico: function(producto) {
      var self = this
      var precio = self.precioProducto(producto)
      return precio * (1 + self.pIva / 100)
    },

    preguntaEliminarProducto: function(producto) {
      var self = this
    
      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "Eliminar producto",
        html: '¿Está seguro que quiere eliminar el producto <strong>'+producto.nombre+'</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          ProductoSrv.eliminar(producto.id).then(response => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success")
            self.refrescarProductos()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar el servicio'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    refrescarProductos: function() {
      var self = this

      iu.spinner.mostrar('#tabla-productos')
      // Actualización de los querys
      this.$router.replace({
        query: {
          dato: self.dato,
          pagina: self.pagina,
          por_pagina: self.por_pagina,
          activos: self.activos
        }
      }).catch(error => {})

      var parametros = {
        pagina: self.pagina,
        por_pagina: self.por_pagina,
        con_categoria: true,
        sin_genericos: true,
        activos: self.activos
      }

      if (self.dato != '') parametros['dato'] = self.dato

      ProductoSrv.productosJSON(parametros).then(response => {
        localStorage.setItem('argusblack.listadoProductos.por_pagina', self.por_pagina)
        let res = response.data
        self.pagina = res.pagina
        self.productos = res.productos
        self.ultimaPagina = res.ultima_pagina
        self.btnPagFin = res.ultima_pagina
        self.nProductosEnPag = res.nProductosEnPag
        self.nProductosTotales = res.total
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los productos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.spinner.ocultar('#tabla-productos')
      })
    }
  },
  mounted() {
    iu.spinner.mostrar('#tabla-productos')
  }
}
</script>

<style scoped>
.btnActivo, .btnInactivo {
  cursor: pointer;
}
.iconproducto{
  width: 30px;
  height: 30px;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border: 0px solid #000000;
  margin-right: 5px;
}
</style>